export const PROOF_ACCESS_PERMISSIONS = {
  OWNED_ONLY: 1,
  TEAMS_ONLY: 2,
  EVERYTHING: 3,
  NONE: 4,
};

export const DOC_TYPES = {
  VIDEO: "video",
  HTML: "html",
  AUDIO: "audio",
  IMAGE: "image",
  DOCUMENT: "document",
  WEB_URL: "web_url"
}

export const ANNOTATION_TYPES = {
  DRAW: 'draw',
  ARROW: 'arrow',
  RECT: 'rect',
  POINT: 'point'
}

export const PROOF_VIEW_MODES = {
  APPROVER_MODE: 0,
  PROOF_OWNER_MODE: 1,
  COMMENTER_MODE: 2,
}

export const TYPES_ENUM = {
  STRING: 'string',
  FUNCTION: 'function'
}

export const DEFAULT_ACCOUNT_NAME = "Ashore";
export const DEFAULT_ACCOUNT_LOGO = "img/logo-large.svg"

export const DEFAULT_PADDING = {
   DEFAULT_X_PADDING: 15,
   DEFAULT_Y_PADDING: 10
}

export const CURSOR_TYPE = {
  DEFAULT: 'default',
  MOVE: 'move',
  POINTER: 'pointer'
}

export const SSO_PROVIDERS = {
  MICROSOFT: "microsoft",
  GOOGLE: "google"
}

export const NOTIFICATION_FREQUENCY_OPTIONS = {
  NEVER: 0,
  WHEN_USER_SESSION_ENDS: 1,
  EVERY_10_MINUTES: 2,
  EVERY_30_MINUTES: 3,
  EVERY_HOUR: 4,
  DAILY: 5
}

export const FILE_TYPE = {
  PDF: 'application/pdf',
}
